import { Badge } from "../../components/Badge.tsx";
import type { MachineCommonFields } from "../pricing/resourcePricing.ts";
import { getPrimaryStorage } from "./vmEntityUtils.ts";

export function MachineSizeInfo({ vm }: { vm: MachineCommonFields }) {
    const { vcpu, memory } = vm;

    const primary = getPrimaryStorage(vm);
    return (
        <div className="flex gap-0.25em">
            <Badge color="text">{vcpu} vCPU</Badge>
            <Badge color="text">{memory / 1024} GB</Badge>
            {primary && <Badge color="text">{primary.size} GB</Badge>}
        </div>
    );
}
